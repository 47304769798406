<template>
  <v-container>
    <v-row class="mt-4">
      <v-col cols="12 my-2">
        <h3>Account Transactions and Financial Data</h3>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <template v-if="account">
        <v-progress-circular
          v-if="localLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-row v-if="financialData">
          <v-col cols="12">
            <v-simple-table height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Status</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Sub Total</th>
                    <th class="text-left">Discount</th>
                    <th class="text-left">
                      CC Processing
                      <v-tooltip top color="info">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">*</span>
                        </template>
                        <span
                          >Credit Card processing fee calculated based on
                          customer's total payment which includes services fees
                          and delivery charges</span
                        >
                      </v-tooltip>
                    </th>
                    <th class="text-left">Tip</th>
                    <th class="text-left">Tax</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Order ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in financialData" :key="item.id">
                    <td>{{ statusLabel(item.status) | capitalize }}</td>
                    <td>{{ item.total | currency }}</td>
                    <td>{{ item.sub_total | currency }}</td>
                    <td>
                      <span class="red--text" v-if="item.total_discount > 0"
                        >-{{ item.total_discount | currency }}</span
                      >
                      <span v-else></span>
                    </td>

                    <td class="red--text">
                      -{{ item.payment_fee | currency }}
                    </td>
                    <td>{{ item.tip | currency }}</td>
                    <td>{{ item.tax | currency }}</td>

                    <td>{{ item.date }}</td>
                    <td>
                      <router-link :to="'/order/view/' + item.id">
                        {{ item.id.substr(0, 4) }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="financialData" align="center">
          <v-col cols="6" sm="2">
            <v-select label="Year" v-model="year" :items="['2020', '2021']">
            </v-select>
          </v-col>
          <v-col cols="6" sm="5">
            <v-select
              multiple
              class="body-2"
              label="Status"
              v-model="status"
              :items="statuses"
            >
            </v-select>
          </v-col>

          <v-col cols="6" sm="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              class="body-2"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range no-title>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <download-csv
            :data="financialData"
            :name="getFileNameByDate()"
            class="btn btn-default pa-4"
          >
            <span>
              <v-icon large color="success">mdi-download</v-icon>
            </span>
          </download-csv>
        </v-row>

        <v-row v-if="monthRange">
          <v-col
            v-for="data in monthRange"
            :key="data.month"
            cols="2"
            class="mb-1"
          >
            <v-card elevation="2">
              <v-card-actions>
                <v-btn text @click="getFinancialDataMonth(data)">
                  <span>
                    <v-row align="center">
                      <v-spacer></v-spacer>
                      <span class="mx-2 red--text title">{{ data.month }}</span>
                    </v-row>
                  </span>
                </v-btn>
              </v-card-actions>

              <v-card-text>
                <template v-if="!localLoading && selectedMonth === data.month">
                  <download-csv
                    v-if="monthData[data.month]"
                    :data="monthData[data.month]"
                    :name="getFileName(data.month)"
                    class="btn btn-default pa-4"
                  >
                    <span>
                      <v-row align="center">
                        <span class="caption">{{
                          monthData[data.month]
                            ? 'Items:' + monthData[data.month].length
                            : ''
                        }}</span>
                        <v-icon
                          v-if="monthData[data.month].length > 0"
                          large
                          color="success"
                          >mdi-download</v-icon
                        >
                      </v-row>
                    </span>
                  </download-csv>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            Setup your bank account using
            <a target="_blank" :href="callBackUrl">
              <v-btn color="blue lighten-2">
                <v-icon large left>fab fa-stripe</v-icon>
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12" v-if="false">
            <a target="_blank" :href="callBackUrlForCode">Link</a> if you have
            existing Stripe account.
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-alert outlined color="error" icon="warning" :value="true"
          >Your account is not verified yet.</v-alert
        >
      </v-col>
    </template>

    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Bank Account"
      message="Are you sure you want to delete this account?"
      @onConfirm="onDeleteAccount"
    ></delete-confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import JsonCSV from 'vue-json-csv'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    'download-csv': JsonCSV
  },
  data() {
    return {
      dates: [
        moment()
          .add(-1, 'w')
          .format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      monthData: {},
      menu: false,
      localLoading: false,
      year: '2020',
      financialData: [],
      monthlyData: {},
      months: [],
      selectedMonth: null,
      statuses: [
        'All',
        'Delivered',
        'Picked up',
        'Cancelled',
        'No Delivery',
        'No Pickup',
        'Rejected',
        'Failed',
        'Accepted'
      ],

      status: null
      /* January: null,
      February: null,
      March: null,
      April: null,
      May: null,
      June: null,
      July: null,
      August: null,
      September: null,
      October: null,
      November: null,
      December: null,*/
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      account: 'bankAccount'
    }),
    callBackUrl() {
      const domain = process.env.VUE_APP_DOMAIN
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://biz.${domain}/callback/account&client_id=${client_id}&state=${this.profile.id}`
    },
    callBackUrlForCode() {
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&state=${this.profile.id}`
    },
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    monthRange() {
      return _.range(12).map(m => {
        return {
          month: moment(`${this.year}-${m + 1}`).format('MMMM'),
          endOf: moment(`${this.year}-${m + 1}`)
            .endOf('month')
            .format('YYYY-MM-DD'),
          startOf: moment(`${this.year}-${m + 1}`)
            .endOf('startOf')
            .format('YYYY-MM-DD')
        }
      })
    }
  },
  watch: {
    dates() {
      if (this.dates && this.dates.length > 0) {
        this.loadData()
      }
    },
    status() {
      if (this.status.includes('All')) {
        this.status = this.statuses
      }
    }
  },
  mounted() {
    if (!this.profile) {
      console.warn('Profile is missing')
      return
    }
    this.loadData()
  },
  methods: {
    statusLabel(status) {
      return status ? status.replace('_', ' ') : ''
    },
    async loadData() {
      this.financialData = []
      this.localLoading = true
      this.financialData = await this.loadTransactions(this.dates)
      this.localLoading = false
    },
    async loadTransactions(dates) {
      let list = []

      return await this.$store
        .dispatch('loadTransfers', { uid: this.profile.id, dates })
        .then(d => {
          this.localLoading = false
          if (d) {
            d = this.filterByStatus(d, this.status)
            d.forEach(charge => {
              if (
                charge.charge &&
                charge.charge.chargeResponse &&
                charge.charge.chargeResponse.amount
              ) {
                const entry = charge.charge.chargeResponse
                const metadata = entry.metadata || {}
                let time = moment
                  .unix(entry.created)
                  .format('MM/DD/YYYY h:mm A')
                if (charge.createdTime) {
                  time = moment(charge.createdTime).format('MM/DD/YYYY h:mm A')
                }

                const payload = {
                  id: charge.orderId,
                  date: time,
                  total: metadata.producer_total,
                  sub_total: metadata.sub_total,
                  delivery_discount:
                    metadata.discount_producer != 'TAKEIN'
                      ? metadata.delivery_discount
                      : 0,
                  discount:
                    metadata.discount_producer != 'TAKEIN'
                      ? metadata.discount
                      : 0,

                  tax: metadata.tax,
                  status: charge.status,
                  tip: metadata.producer_tip,
                  service_fee: metadata.producer_service_fee,
                  payment_fee: metadata.producer_payment_fee,
                  created: entry.created,
                  paymentDate: moment(entry.createdTime).format('L')
                }
                payload.total_discount =
                  parseFloat(payload.delivery_discount) +
                  parseFloat(payload.discount)
                list.push(payload)
              }
            })
            list = list.sort(function(a, b) {
              return a.created < b.created ? 1 : -1
            })
          }

          return list
        })
        .catch(e => {
          this.localLoading = false
          console.log(e)
          this.$store.dispatch('setError', {
            message: 'Unable to load Payments'
          })
        })
    },

    getFileName(month) {
      return month + '_' + this.profile.handle + '_financial_data.csv'
    },
    getFileNameByDate() {
      return this.dates.join('-') + '_financial_data.csv'
    },
    getFinancialData(month) {
      const m = moment(`${month}/${this.year}`, 'MMM/Y')
      return this.financialData.filter(o =>
        moment.unix(o.created).isSame(m, 'month')
      )
    },
    getFinancialDataMonth(data) {
      this.localLoading = true

      if (!this.months.includes(data.month)) {
        // this.months.push(data.month)
      }

      this.loadTransactions([data.startOf, data.endOf]).then(list => {
        this.selectedMonth = data.month
        this.monthData[data.month] = list
        this.localLoading = false
      })
    },
    deleteAccount(id) {
      this.id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteAccount() {
      this.$refs.deleteConfirmationDialog.close()
      this.$store.dispatch('deleteBankAccount', {
        uid: this.profile.id,
        id: this.account.stripe_user_id
      })
    },
    filterByStatus(list, status) {
      if (!list || !status) {
        return list
      }
      status = status.map(l => l.toLowerCase()).map(l => l.replace(' ', '_'))

      if (status.includes('all')) {
        return list
      }
      list = list.filter(data => status.includes(data.status))

      return list
    }
  }
}
</script>
